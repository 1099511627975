<template>
  <div v-if="news">
    <transition name="fade">
      <div
        id="newsHeader"
        v-if="news && news.better_featured_image"
        :style="`background-image: url('${news.better_featured_image.source_url}');background-size:cover;`"
      >
        <div class="d-flex justify-content-center align-items-center">
          <h1
            class="mb-3 text-white text-center"
            v-html="news.title.rendered"
          ></h1>
        </div>
      </div>
    </transition>
    <transition name="fade">
      <div class="container" style="margin-top: 50px" v-if="news">
        <div class="row">
          <div class="col-12 col-lg-9 entry-content">
            <div
              class="container topic-container mb-5 overflow-visible p-5"
              style="height: auto"
              v-if="news"
            >
              <div class="news-header full-width">
                <div
                  class="d-flex text-purple flex-wrap justify-content-center align-items-center"
                >
                  <div class="d-flex align-items-center mx-3 my-1">
                    <i class="far fa-eye mr-1"></i>
                    {{ (pageViews / 2) | toFixed(0) }} wyświetleń
                  </div>
                  <div class="d-flex align-items-center mx-3 my-1">
                    <i class="far fa-calendar-alt mr-1"></i>
                    {{ news.date | formatDate }}
                  </div>
                  <div class="d-flex align-items-center mx-3 my-1">
                    <i class="fas fa-user mr-1"></i>
                    {{ news._embedded.author[0].name }}
                  </div>
                  <EditPostLink :id="news.id" />
                </div>
                <h2
                  class="mb-5 mt-5 d-flex justify-content-center"
                  v-html="news.title.rendered"
                ></h2>
              </div>
              <div v-html="news.content.rendered"></div>

              <div class="d-flex justify-content-end mt-4">
                <LikeButton :url="location" />
              </div>

              <PostComments :post="news" />
            </div>
            <div class="container" v-else>
              <div class="row">
                <div class="col-12 d-flex justify-content-center">
                  <Loader />
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-3">
            <CategoriesBox />
            <LastNewsBox />
          </div>
        </div>
      </div>
    </transition>
  </div>
  <div
    v-else
    style="min-height: 80vh"
    class="py-5 d-flex justify-content-center"
  >
    <Loader class="my-5" />
  </div>
</template>
<script>
import Loader from "../components/Loader";
import CategoriesBox from "../components/CategoriesBox";
import LastNewsBox from "../components/LastNewsBox";
import PostComments from "../components/PostComments";
import LikeButton from "../components/LikeButton";
import EditPostLink from "../components/EditPostLink";
export default {
  components: {
    EditPostLink,
    LikeButton,
    PostComments,
    LastNewsBox,
    CategoriesBox,
    Loader,
  },
  data() {
    return {
      news: null,
      pageViews: 0,
      location: window.location.href,
    };
  },
  watch: {
    slug() {
      this.pageViews = 0;
      this.news = null;
      this.getNews();
    },
  },
  mounted() {
    this.getNews();
  },
  methods: {
    likePost() {
      this.$http.post(
        `/acf/v2/post/${this.news.id}?trand=iHxmKppCluZD9v872ce4KoSrpwDlsmoc5VaWNmjJ`,
        "fields[like]=1"
      );
    },
    getPostViews() {
      this.$http
        .get(
          `aalaap/v1/countview/${this.news.id}?_embed&trand=iHxmKppCluZD9v872ce4KoSrpwDlsmoc5VaWNmjJ`,
          { id: this.id }
        )
        .then((response) => {
          this.pageViews = response.data;

          this.$nextTick(() => {
            // eslint-disable-next-line no-undef
            $(".blocks-gallery-item a").fancybox();
          });
        });
    },
    getNews() {
      this.$http
        .get(
          `wp/v2/posts?slug=${this.slug}&_embed&trand=iHxmKppCluZD9v872ce4KoSrpwDlsmoc5VaWNmjJ`,
          this.config ? this.config : null
        )
        .then((response) => {
          this.news = response.data[0];
          this.getPostViews();
          //
        });
    },
  },
  computed: {
    user() {
      return this.$store.getters.userData;
    },
    config() {
      return this.$store.getters.config;
    },
    slug() {
      return this.$route.params.slug;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "src/assets/scss/colors";

.image-featured {
  height: 200px;
  background-size: cover;
  background-position: center;
  margin-bottom: 50px;
}
#newsHeader {
  height: 386px;
  display: flex;
  align-items: center;
  background-size: cover;
  background-position: center;
  justify-content: center;
  position: relative;
  h1 {
    position: relative;
    z-index: 10;
  }
  &:after {
    width: 100%;
    height: 100%;
    position: absolute;
    background: rgba(46, 58, 89, 0.6);
    content: "";
  }
}
.news-header {
  border-bottom: 2px dashed $purple;
  margin-bottom: 50px;
}
</style>
