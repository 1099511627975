<template>
  <a
    target="_blank"
    :href="`https://old-support.hrsys.pl/wp-admin/post.php?post=${id}&action=edit&trand=iHxmKppCluZD9v872ce4KoSrpwDlsmoc5VaWNmjJ`"
    v-if="user && user.roles.includes('administrator')"
    class="d-flex text-purple align-items-center mx-5"
  >
    <i class="fas fa-edit mr-1"></i> Edytuj
  </a>
</template>

<script>
export default {
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  computed: {
    user() {
      return this.$store.getters.userData;
    },
  },
  name: "EditPostLink",
};
</script>

<style scoped></style>
