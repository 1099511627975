<template>
  <div class="main-container">
    <div id="faqHeader" style="padding-bottom: 0; margin-bottom: 0">
      <div class="container">
        <h1 class="text-center">Najczęściej zadawane pytania</h1>
        <h3 class="text-center">
          Zapoznaj się z najczęściej zadawanymi pytaniami. <br />
          One wyjaśnią jak najwięcej Twoich wątpliwości!
        </h3>
        <div class="row">
          <div class="col-12 col-lg-2"></div>
          <div class="col-12 col-lg-8 d-flex justify-content-center">
            <div
              class="d-flex justify-content-center align-items-center mb-5 full-width flex-column"
            >
              <SearchBar />
            </div>
          </div>
          <div class="col-12 col-lg-2"></div>
        </div>
      </div>
    </div>
    <div class="container mt-5" v-if="!loading">
      <div class="row">
        <div class="col-12 col-lg-4">
          <h3 class="font-weight-bold mb-5">Kategorie</h3>
          <ul class="m-0 p-0">
            <li
              @click="currentCategory = category"
              class="d-flex"
              v-for="category in categories"
              :key="category.term_id"
            >
              <div class="category-button">
                {{ category.name }}
              </div>
            </li>
          </ul>
        </div>
        <div class="col-12 col-lg-8">
          <h3 class="font-weight-bold">Pytania i odpowiedzi</h3>
          <div class="topic-container mt-5 overflow-visible px-3">
            <div class="row mt-0">
              <div
                class="col-12 question-parent"
                :class="{ open: question.open }"
                :key="question.ID"
                v-for="question in currentCategory.posts"
              >
                <div
                  @click="openPost(question)"
                  class="question-row d-flex justify-content-between cursor-pointer"
                >
                  <div class="d-flex align-items-center full-width">
                    <div class="question-letter mr-3">Q</div>
                    <h5 class="mb-0 question-title">
                      {{ question.post_title }}
                    </h5>
                  </div>
                  <div class="d-flex align-items-center">
                    <div class="toggle-post">
                      <i class="fas fa-chevron-down" v-if="!question.open"></i>
                      <i class="fas fa-chevron-up" v-else></i>
                    </div>
                  </div>
                </div>
                <transition name="slide-down">
                  <div class="row mt-3" v-if="question.open">
                    <div class="col-12 full-width">
                      <div class="d-flex align-items-start">
                        <div class="answer-letter mr-3">A</div>
                        <div
                          class="mb-0 question-content"
                          v-html="question.post_content"
                        ></div>
                      </div>
                    </div>
                  </div>
                </transition>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container mt-5 d-flex justify-content-center" v-else>
      <Loader />
    </div>
  </div>
</template>

<script>
import SearchBar from "../components/SearchBar";
import Loader from "../components/Loader";
import forEach from "lodash.foreach";
export default {
  name: "Faq",
  components: { Loader, SearchBar },
  data() {
    return {
      categories: [],
      currentCategory: null,
      loading: true,
    };
  },
  mounted() {
    this.$http
      .get("wp/v2/manualfaq?trand=iHxmKppCluZD9v872ce4KoSrpwDlsmoc5VaWNmjJ")
      .then((response) => {
        this.categories = response.data;
        this.currentCategory = this.categories[0];
        this.loading = false;
      });
  },
  methods: {
    openPost(post) {
      if (post.open) {
        post.open = false;
        return;
      }
      forEach(this.currentCategory.posts, (post) => {
        post.open = false;
      });
      this.$nextTick(() => {
        post.open = true;
      });
    },
  },
};
</script>

<style lang="scss">
.question-content {
  max-width: 90%;
  font-size: 18px;
  padding-bottom: 30px;
  * {
    color: #414141 !important;
  }
  font-weight: 400;
}
</style>
<style scoped lang="scss">
@import "src/assets/scss/colors";
.topic-container {
  height: initial;
}
.toggle-post {
  background: #edf1f7;
  width: 30px;
  cursor: pointer;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  font-size: 12px;
  color: $darkBlue2;
}
.question-parent {
  padding-bottom: 20px;
  padding-top: 20px;
  border-bottom: 1px dashed #dadada;
  background: transparent;
  box-shadow: none;
  transition-duration: 0.5s;
  &.open {
    background: #edf1f7;
    box-shadow: inset 0px 0px 20px rgba(1, 60, 158, 0.12);
  }
  &:last-of-type {
    border-bottom: 0;
  }
}
.question-row {
  .question-title {
    font-size: 18px;
    font-weight: 400;
    max-width: 80%;
  }
}
.question-letter {
  font-size: 24px;
  color: $purple;
  font-weight: 700;
}
.answer-letter {
  font-size: 24px;
  color: $darkBlue2;
  font-weight: 700;
}
.category-button {
  font-size: 24px;
  color: #2e3a59;
  font-weight: 600;
  margin-bottom: 10px;
  cursor: pointer;
}
</style>
