import { render, staticRenderFns } from "./CategoriesBox.vue?vue&type=template&id=9b47ab46&scoped=true&"
import script from "./CategoriesBox.vue?vue&type=script&lang=js&"
export * from "./CategoriesBox.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9b47ab46",
  null
  
)

export default component.exports