<template>
  <div id="header" style="padding-bottom: 0; margin-bottom: 0">
    <div class="container">
      <h1 class="text-center">Do pobrania</h1>
      <h3 class="text-center">
        Praktyczne materiały graficzne do pobrania: plakaty, tła pulpitu,<br />
        tła prezentacji, naklejki dla pracowników
      </h3>
      <div class="row mt-5">
        <div class="col-12" v-if="pageData">
          <div class="row page-items" v-html="pageData.content.rendered"></div>
        </div>
        <div
          class="col-12 d-flex justify-content-center mt-5"
          style="min-height: 700px"
          v-else
        >
          <Loader />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from "../components/Loader";

export default {
  name: "Download",
  components: { Loader },
  data() {
    return {
      pageData: null,
    };
  },
  mounted() {
    this.$http
      .get("wp/v2/pages/6411?trand=iHxmKppCluZD9v872ce4KoSrpwDlsmoc5VaWNmjJ")
      .then((response) => {
        this.pageData = response.data;
      });
  },
};
</script>

<style lang="scss">
.gallery-image {
  width: 100%;
  height: 215px;
  border-radius: 16px;
  overflow: hidden;
  background-size: cover;
  background-position: center;
}

.gallery-title__caption {
  color: #2e3a59;
  font-size: 16px;
  margin-top: 24px;
  margin-bottom: 32px;
  font-weight: 700;
  display: flex;
}
</style>
