<template>
  <div>
    <div id="courseBookHeader">
      <div class="container">
        <h1 class="text-center">Poradnik HR</h1>
        <h3 class="text-center">
          Poznaj wskazówki naszych ekspertów dotyczące <br />najważniejszych
          procesów HR
        </h3>
      </div>
      <div class="container homepage-containers">
        <div class="row">
          <a
            href="https://www.youtube.com/watch?v=uP74WOZBR2w&ab_channel=Hsys"
            target="_blank"
            class="col-12 col-md-6 col-lg-4 mb-4"
          >
            <div
              href="/knowledge-base/guide"
              class="knowledge-base-container user tutorial d-flex flex-column align-items-center"
            >
              <div
                class="main-photo"
                style="background-image: url('/img/youtube-video1.jpg')"
              ></div>
              <p
                class="text-blue text-left full-width py-3 px-3 font-weight-bold"
              >
                Feedback w organizacji
              </p>
            </div>
          </a>
          <a
            href="https://www.youtube.com/watch?v=two0EFDLlAA&ab_channel=Hsys"
            target="_blank"
            class="col-12 col-md-6 col-lg-4 mb-4"
          >
            <div
              href="/knowledge-base/guide"
              class="knowledge-base-container user tutorial d-flex flex-column align-items-center"
            >
              <div
                class="main-photo"
                style="background-image: url('/img/youtube-video2.jpg')"
              ></div>
              <p
                class="text-blue text-left full-width py-3 px-3 font-weight-bold"
              >
                Zarządzanie Celami
              </p>
            </div>
          </a>
          <a
            href="https://www.youtube.com/watch?v=1GOxy1Dwwog&ab_channel=Hsys"
            target="_blank"
            class="col-12 col-md-6 col-lg-4 mb-4"
          >
            <div
              href="/knowledge-base/guide"
              class="knowledge-base-container user tutorial d-flex flex-column align-items-center"
            >
              <div class="main-photo"></div>
              <p
                class="text-blue text-left full-width py-3 px-3 font-weight-bold"
              >
                Onboarding w praktyce HR cz 1
              </p>
            </div>
          </a>
          <a
            href="https://www.youtube.com/watch?v=xM71MVlJHko&ab_channel=Hsys"
            target="_blank"
            class="col-12 col-md-6 col-lg-4 mb-4"
          >
            <div
              href="/knowledge-base/guide"
              class="knowledge-base-container user tutorial d-flex flex-column align-items-center"
            >
              <div class="main-photo"></div>
              <p
                class="text-blue text-left full-width py-3 px-3 font-weight-bold"
              >
                Onboarding w praktyce HR cz 2
              </p>
            </div>
          </a>
          <a
            href="https://www.youtube.com/watch?v=BbnnkyW4xBw&ab_channel=Hsys"
            target="_blank"
            class="col-12 col-md-6 col-lg-4 mb-4"
          >
            <div
              href="/knowledge-base/guide"
              class="knowledge-base-container user tutorial d-flex flex-column align-items-center"
            >
              <div class="main-photo"></div>
              <p
                class="text-blue text-left full-width py-3 px-3 font-weight-bold"
              >
                Onboarding w praktyce HR cz 3
              </p>
            </div>
          </a>
          <a
            href="https://www.youtube.com/watch?v=BgUEpKHck50&ab_channel=Hsys"
            target="_blank"
            class="col-12 col-md-6 col-lg-4 mb-4"
          >
            <div
              href="/knowledge-base/guide"
              class="knowledge-base-container user tutorial d-flex flex-column align-items-center"
            >
              <div
                class="main-photo"
                style="background-image: url('/img/youtube-video8.jpg')"
              ></div>
              <p
                class="text-blue text-left full-width py-3 px-3 font-weight-bold"
              >
                Baza wiedzy w organizacji
              </p>
            </div>
          </a>
          <a
            href="https://www.youtube.com/watch?v=mhmhEon-bgQ&ab_channel=Hsys"
            target="_blank"
            class="col-12 col-md-6 col-lg-4 mb-4"
          >
            <div
              href="/knowledge-base/guide"
              class="knowledge-base-container user tutorial d-flex flex-column align-items-center"
            >
              <div
                class="main-photo"
                style="background-image: url('/img/youtube-video9.jpg')"
              ></div>
              <p
                class="text-blue text-left full-width py-3 px-3 font-weight-bold"
              >
                Efektywne zarządzanie szkoleniami w organizacji
              </p>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: "KnowledgeBaseHRGuide",
};
</script>
