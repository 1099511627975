<template>
  <iframe
    :src="`https://www.facebook.com/plugins/like.php?href=${url}&width=150&layout=button_count&action=like&size=small&share=true&height=46&appId=449402485794457`"
    width="100%"
    height="46"
    style="border: none; overflow: hidden; text-align: right"
    scrolling="no"
    frameborder="0"
    allowfullscreen="true"
    allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
  ></iframe>
</template>

<script>
export default {
  name: "LikeButton",
  props: {
    url: {
      required: true,
      type: String,
    },
  },
};
</script>
