<template>
  <div v-if="post">
    <div
      id="newsHeader"
      v-if="post.better_featured_image"
      :style="`background-image: url('${news.better_featured_image.source_url}')`"
    >
      <div class="d-flex justify-content-center align-items-center">
        <h1 class="mb-3 text-white" v-html="post.title.rendered"></h1>
      </div>
    </div>
    <div class="container" style="margin-top: 50px">
      <div class="row">
        <div class="col-12 col-lg-9">
          <div
            class="container topic-container overflow-visible p-5"
            style="height: auto"
            v-if="post"
          >
            <div class="news-header full-width">
              <div
                class="d-flex text-purple justify-content-center align-items-center"
              >
                <div class="d-flex align-items-center mx-3">
                  <i class="far fa-eye mr-1"></i>
                  {{ pageViews }} wyświetleń
                </div>
                <div class="d-flex align-items-center mx-3">
                  <i class="far fa-calendar-alt mr-1"></i>
                  {{ post.post_date | formatDate }}
                </div>
                <div class="d-flex align-items-center mx-3">
                  <i class="fas fa-user mr-1"></i>
                  {{ post.author }}
                </div>

                <EditPostLink :id="post.ID" />
              </div>
              <h2
                class="mb-5 mt-5 d-flex justify-content-center"
                v-html="post.post_title"
              ></h2>
            </div>
            <div v-html="post.post_content"></div>

            <div class="d-flex justify-content-end mt-4">
              <LikeButton :url="location" />
            </div>
          </div>
          <div class="container" v-else>
            <div class="row">
              <div class="col-12 d-flex justify-content-center">
                <Loader />
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-3">
          <CategoriesBox />
          <LastNewsBox />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Loader from "../components/Loader";
import CategoriesBox from "../components/CategoriesBox";
import LastNewsBox from "../components/LastNewsBox";
import LikeButton from "../components/LikeButton";
import EditPostLink from "../components/EditPostLink";
export default {
  components: { EditPostLink, LikeButton, LastNewsBox, CategoriesBox, Loader },
  data() {
    return {
      post: null,
      pageViews: 0,
      location: window.location.href,
    };
  },
  watch: {
    id() {
      this.post = null;
      window.scrollTo(0, 700);
      this.getPost();
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.getPostViews();
      this.getPost();
    });
  },
  methods: {
    getPostViews() {
      this.$http
        .get(
          `aalaap/v1/countview/${this.id}?_embed&trand=iHxmKppCluZD9v872ce4KoSrpwDlsmoc5VaWNmjJ`,
          { id: this.id }
        )
        .then((response) => {
          this.pageViews = response.data;
        });
    },
    getPost() {
      this.$http
        .get(
          `wp/v2/getCustomPostById?id=${this.id}}&_embed&trand=iHxmKppCluZD9v872ce4KoSrpwDlsmoc5VaWNmjJ`,
          { id: this.id }
        )

        .then((response) => {
          this.post = response.data;
          window.scrollTo(0, 0);
        });
    },
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    id() {
      return this.$route.params.id;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "src/assets/scss/colors";

.image-featured {
  height: 200px;
  background-size: cover;
  background-position: center;
  margin-bottom: 50px;
}
#newsHeader {
  height: 586px;
  display: flex;
  align-items: center;
  background-size: cover;
  background-position: center;
  justify-content: center;
  position: relative;
  h1 {
    position: relative;
    z-index: 10;
  }
  &:after {
    width: 100%;
    height: 100%;
    position: absolute;
    background: rgba(46, 58, 89, 0.6);
    content: "";
  }
}
.news-header {
  border-bottom: 2px dotted $purple;
  margin-bottom: 50px;
}
</style>
