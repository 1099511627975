<template>
  <div class="row mt-0 mb-5">
    <div class="col-12">
      <h3>Najnowsze wpisy</h3>
      <ul class="d-flex flex-column ml-0 pl-0 box-listing">
        <li
          :class="{ active: slug === topic.slug }"
          v-for="topic in news"
          :key="topic.id"
        >
          <router-link
            v-html="topic.title.rendered"
            :to="`/topic/${topic.slug}`"
          >
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "LastNewsBox",
  data() {
    return {
      news: [],
      activeCategory: null,
    };
  },
  mounted() {
    this.getLastNews();
  },
  computed: {
    slug() {
      return this.$route.params.slug;
    },
  },
  methods: {
    getLastNews() {
      this.$http
        .get(
          `wp/v2/posts?per_page=5&trand=iHxmKppCluZD9v872ce4KoSrpwDlsmoc5VaWNmjJ`
        )
        .then((response) => {
          this.news = response.data;
        });
    },
  },
};
</script>

<style scoped></style>
