<template>
  <div>
    <div id="header">
      <div class="container">
        <h1 class="text-center">Baza wiedzy</h1>
        <h3 class="text-center">
          Praktyczne materiały, nagrania, porady ekspertów HR <br />zebrane dla
          Ciebie w jednym miejscu
        </h3>
      </div>
      <KnowledgeBaseContainers />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import KnowledgeBaseContainers from "../components/KnowledgeBaseContainers";
export default {
  name: "KnowledgeBase",
  components: { KnowledgeBaseContainers },
};
</script>
