<template>
  <div class="container homepage-containers">
    <div class="row">
      <div class="col-12 col-md-6 col-lg-3">
        <router-link
          to="/knowledge-base/guide"
          class="homepage-container admin d-flex flex-column align-items-center text-center"
        >
          <div class="icon mb-4">
            <img src="/img/icons/Icon_book.png" alt="" />
          </div>
          <h4>Przewodnik</h4>
          <p>
            Twój osobisty przewodnik po wszystkich etapach współpracy z nami
          </p>
        </router-link>
      </div>
      <div class="col-12 col-md-6 col-lg-3">
        <router-link
          to="/knowledge-base/tutorials"
          class="homepage-container admin d-flex flex-column align-items-center text-center"
        >
          <div class="icon mb-4">
            <img src="/img/icons/Icon_book-1.png" alt="" />
          </div>
          <h4>Tutoriale</h4>
          <p>
            Filmy instuktażowe oraz tip&tricks ułatwiające korzystanie z systemu
          </p>
        </router-link>
      </div>
      <div class="col-12 col-md-6 col-lg-3">
        <router-link
          to="/knowledge-base/hr-guide"
          class="homepage-container admin d-flex flex-column align-items-center text-center"
        >
          <div class="icon mb-4">
            <img src="/img/icons/Icon_documentation.png" alt="" />
          </div>
          <h4>Poradnik HR</h4>
          <p>
            Poznaj wskazówki naszych ekspertów dotyczące najważniejszych
            procesów HR
          </p>
        </router-link>
      </div>
      <div class="col-12 col-md-6 col-lg-3">
        <router-link
          to="/knowledge-base/webinars"
          class="homepage-container admin d-flex flex-column align-items-center text-center"
        >
          <div class="icon mb-4">
            <img src="/img/icons/Icon_webinary.png" alt="" />
          </div>
          <h4>Webinary</h4>
          <p>
            Rozwijaj swoją wiedzę i kompetencje dzięki naszym cyklicznym
            webinarom!
          </p>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "KnowledgeBaseContainers",
};
</script>
