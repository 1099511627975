<template>
  <div>
    <div id="header">
      <div class="container">
        <h1>Wyniki wyszukiwania</h1>
        <h3>Wyszukiwanie według: “{{ searchVal }}”</h3>
        <SearchBar @updateSearch="updateSearch" :searchWord="searchVal" />
      </div>
      <div
        class="container d-flex full-width justify-content-center"
        style="margin-top: 100px"
      >
        <div class="row">
          <div class="col-12 mt-5 d-flex justify-content-center" v-if="loading">
            <Loader class="mt-5" />
          </div>
          <div
            class="col-12 mt-5"
            v-else-if="!loading && !searchResults.length"
          >
            <h3>Brak wyników</h3>
          </div>
          <div
            class="col-12 search-post-parent"
            :key="post.id"
            v-for="post in searchResults"
          >
            <router-link
              :to="`/topic/${post.slug}`"
              class="d-flex search-post pb-5 mb-5 full-width align-items-start"
            >
              <div
                class="icon-container d-flex align-items-start justify-content-start mr-4"
              >
                <i class="far fa-file-alt"></i>
              </div>
              <div class="post-details d-flex flex-column">
                <h5 class="post-title">
                  <text-highlight :queries="[searchVal]">
                    {{ post.title.rendered }}
                  </text-highlight>
                </h5>
                <div class="d-flex">
                  <div class="d-flex text-purple align-items-center mr-3">
                    <i class="far fa-calendar-alt mr-1"></i>
                    {{ post.date | formatDate }}
                  </div>
                  <div class="d-flex text-purple align-items-center mx-3">
                    <i class="fas fa-user mr-1"></i>
                    <text-highlight :queries="[searchVal]">
                      {{ post._embedded.author[0].name }}
                    </text-highlight>
                  </div>
                </div>

                <div class="full-width mt-4 post-content">
                  <text-highlight :queries="[searchVal]">
                    {{ post.excerpt.rendered | striphtml }}
                  </text-highlight>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import SearchBar from "../components/SearchBar";
import debounce from "lodash.debounce";
import Loader from "../components/Loader";
import Vue from "vue";
import TextHighlight from "vue-text-highlight";

Vue.component("text-highlight", TextHighlight);

export default {
  name: "Home",
  components: { Loader, SearchBar },
  data: () => {
    return {
      searchVal: "",
      searchResults: [],
      loading: true,
    };
  },
  mounted() {
    this.searchVal = this.$route.params.search || "";
    this.searchData();
  },
  watch: {
    searchVal: debounce(function () {
      this.searchData();
    }, 500),
  },
  methods: {
    updateSearch(data) {
      this.searchVal = data;
    },
    searchData() {
      this.loading = true;
      this.searchResults = [];

      this.$http
        .get(
          `wp/v2/posts?search=${this.searchVal}&_embed&per_page=20&trand=iHxmKppCluZD9v872ce4KoSrpwDlsmoc5VaWNmjJ`
        )
        .then((response) => {
          this.searchResults = response.data.length ? response.data : [];
          this.loading = false;
        });
    },
  },
};
</script>
