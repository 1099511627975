<template>
  <div class="row mt-0 mb-5">
    <div class="col-12">
      <h3>Kategorie</h3>
      <ul class="d-flex flex-column ml-0 pl-0 box-listing">
        <li
          :class="{ active: categoryId === category.id }"
          v-for="category in categories"
          :key="category.id"
        >
          <router-link :to="`/news/${category.id}`">
            {{ category.name }}
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  name: "CategoriesBox",
  data() {
    return {
      categories: [],
      activeCategory: null,
    };
  },
  computed: {
    categoryId() {
      return parseInt(this.$route.params.categoryId);
    },
  },
  mounted() {
    this.getCategories();
  },
  methods: {
    getCategories() {
      this.$http
        .get("wp/v2/categories?trand=iHxmKppCluZD9v872ce4KoSrpwDlsmoc5VaWNmjJ")
        .then((response) => {
          this.categories = _.filter(response.data, (category) => {
            if (category.slug !== "tutoriale") {
              return category;
            }
          });
          if (this.categoryId) {
            this.activeCategory = this.categoryId;
          }
        });
    },
  },
};
</script>

<style scoped></style>
